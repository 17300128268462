import BaseModule from './utils/BaseModule'
import store from '@/store'
import { baseconfig } from "@/config.js";
class BaseManager extends BaseModule {
  constructor() {
    super()
  }


  //获取单个字典
  getDictByCode (param) {
    return this.get('/base/dict/getDictByCode', param)
  }


  //客户基本信息
  getByMultiId (param) {
    return this.get('/customer/customerInfo/getByMultiId', param)
  }

  //获取用户活跃度 
  getCustomerActiveInfo (param) {
    return this.get('/customer/customerInfo/getCustomerActiveInfo', param)
  }

  //获取用户活动信息
  pageActivity (param) {
    return this.postJson('/customer/customerInfo/pageActivity', param)
  }
  //获取用户入店记录
  pageEntry (param) {
    return this.postJson('/customer/customerInfo/pageEntry', param)
  }

  //获取用户订单
  pageOrder (param) {
    return this.postJson('/customer/customerInfo/pageOrder', param)
  }

  //获取历史主诉列表 
  chiefSearch (param) {
    return this.postJson('/customer/customerDemand/search', param)
  }

  //获取最近的客户主诉 
  getLastDemand (param) {
    return this.get('/customer/customerDemand/getLastDemand', param)
  }

  //小程序测肤详情 
  getByDetectResultId (param) {
    return this.get('/customer/customerAiDetectResult/getByDetectResultId', param)
  }
  //获取小程序测肤列表 
  detectRecord (param) {
    return this.postJson('/customer/customerAiDetectResult/page', param)
  }

  //获取最近一次小程序测肤 
  getDetectRecord (param) {
    return this.get('/customer/customerAiDetectResult/getLastRecord', param)
  }
  //皮肤树 
  getBySourceType (param) {
    return this.get('/customer/customerSkinFeatureClassify/getBySourceType', param)
  }

  //获取最近面诊记录
  getLastRecord (param) {
    return this.get('/customer/customerInterview/getLastRecord', param)
  }

  //获取面诊列表 
  pageRecord (param) {
    return this.postJson('/customer/customerInterview/page', param)
  }


  //获取最近的设备检测
  terminal (param) {
    return this.get('/customer/customerTerminalScanningResult/getLastRecord', param)
  }
  //获取设备检测列表
  detecList (param) {
    return this.postJson('/customer/customerTerminalScanningResult/page', param)
  }
  // 获取综合皮肤档案最近一次 
  skinSummary (param) {
    return this.get('/customer/customerSkinSummary/getLastRecord', param)
  }
  //分页获取综合皮肤档案 
  skinSummaryPage (param) {
    return this.postJson('/customer/customerSkinSummary/page', param)
  }


  /**
   * 私密档案
   */
  // 获取最近一条记录
  getLastPrivateFileInfo (param) {
    return this.get('/customer/customerPrivateFile/getLastPrivateFile', param)
  }
  // 2.分页获取
  getPrivateFileList (param) {
    return this.postJson('/customer/customerPrivateFile/search', param)
  }
  // 增加或更新
  postAddOrEditPrivateFileInfo (param) {
    return this.postJson('/customer/customerPrivateFile/createOrUpdate', param)
  }


  /**
   * 客户跟踪任务
   */
  // 任务分类（事件分类）列表
  getTrackEventTypeInfo (param) {
    return this.get('/base/dict/getDictByCode', { sysCode: 'operation', sysBizCode: 'track', dictCode: 'operationTrackEventTypeEnum', ...param })
  }
  // 任务状态列表 -- 入参（固定值）sysCode：operation、sysBizCode：track、dictCode：operationTrackTaskStatusEnum
  getTrackTaskStatusInfo (param) {
    return this.get('/base/dict/getDictByCode', { sysCode: 'operation', sysBizCode: 'track', dictCode: 'operationTrackTaskStatusEnum', ...param })
  }
  // 任务查询
  getTrackTaskSearchInfo (param) {
    return this.postJson('/operation/track/task/search', param)
  }
  // 已被客户删除
  postTrackTaskLossInfo (param) {
    return this.postJson('/operation/track/task/loss', param)
  }
  // 完成回访
  postTrackTaskFinishedInfo (param) {
    return this.postJson('/operation/track/task/finished', param)
  }


  /**
   * 客户治疗方案
   */
  getTreatmentPlanInfo (param) {
    return this.get('/wz/treatment/getCustTreatment', param)
  }




}

export default new BaseManager()
